import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildLoadConfigurationCategoriesUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import {
    CATEGORY_TYPES,
    CATEGORY_TYPES_RESPONSE_KEY,
    CATEGORY_TYPES_URL_SEGMENT,
    ConfigurationCategory,
} from '../types';

export const loadConfigurationCategories = (
    filters: FilterObject[],
    type: CATEGORY_TYPES,
): Observable<ConfigurationCategory[]> => {
    let convertedType;
    let urlSegment;
    if (type === CATEGORY_TYPES.DISPATCH_HEADER) {
        convertedType = CATEGORY_TYPES.HEADER;
        urlSegment = CATEGORY_TYPES.HEADER;
    } else if (type === CATEGORY_TYPES.VALIDATION_RULE) {
        convertedType = CATEGORY_TYPES_RESPONSE_KEY.VALIDATION_RULE;
        urlSegment = CATEGORY_TYPES_URL_SEGMENT.VALIDATION_RULE;
    } else {
        convertedType = type;
        urlSegment = type;
    }
    const obs = sendRetrieveRequest(buildLoadConfigurationCategoriesUrl(filters, urlSegment));
    const responseKey = `${convertedType}Category`;

    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const categories: ConfigurationCategory[] = (response[responseKey] as any[]).map(
                (x) => ({
                    ...convertToCamel(x),
                    categoryType: type,
                }),
            );
            return observableOf(categories);
        }),
    );
};
